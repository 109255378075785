import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Alert } from '@modules/alerts/models';
import { Observable } from 'rxjs';
import { UtilidadesService } from '../utilidades.service';
import { LoginService } from '../login/login.service';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { Respuesta } from '@app/models/Respuesta.model';

@Injectable({
  providedIn: 'root'
})
export class NotificacionService {

  constructor(
    private http: HttpClient,
    private utilidadesService: UtilidadesService,
    private loginService: LoginService,
  ) { }

  async getNotifications(): Promise<Alert[]> {

    var usuario = await this.loginService.getStorage();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + usuario.token
      })
    };
    let paramsInfoApp = await this.loginService.getInfoApi();
    let params = new HttpParams({fromString: paramsInfoApp })
    
    return await this.http.post( environment.apiUrl + 'api/awt/getNotifications', params, httpOptions)
          .pipe( map((info:any) => {
            let notificaciones:Alert[] = [];
            let data = info.data;
            if (data){
              data.forEach((elemento : any) => {
                let alert = {
                  idnotificacion: elemento.idnotificacion,
                  icon: elemento.icon,
                  text: elemento.text,
                  date: elemento.date,
                  leido: elemento.flg_leido
                };
                notificaciones.push(alert);
              });
            }
            return notificaciones;
          })).toPromise();
  }

  getNotificationsObservale(page:number, pageSize:number, criterio:string): Observable<Alert[]> {

    var usuario = this.loginService.getStorage();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + usuario.token
      })
    };
    let paramsInfoApp = this.loginService.getInfoApi();
    let params = new HttpParams({fromString: 'page=' + page.toString() + '&pageSize=' + pageSize.toString() + '&criterio=' + criterio + paramsInfoApp })
    
    return this.http.post( environment.apiUrl + 'api/awt/getNotificationsPaginator', params, httpOptions)
          .pipe( map((info:any) => {
            let notificaciones:Alert[] = [];
            let total = info.data.total;
            let data = info.data.items;
            if (data){
              data.forEach((elemento : any) => {
                let alert = {
                  idnotificacion: elemento.idnotificacion,
                  icon: '',
                  text: elemento.text,
                  date: elemento.date,
                  leido: elemento.flg_leido,
                  username: elemento.username,
                  totalPaginator: total
                };
                notificaciones.push(alert);
              });
            }

            // 1. # Valida error
            if (info.status == 'error'){
              this.utilidadesService.showToast('Error',info.msj,{headerClasses:'text-danger'});
            }

            return notificaciones;
          }));
  }

  async setLeidoNotification(idnotificacion:number):Promise<Respuesta>{
    var usuario = await this.loginService.getStorage();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + usuario.token
      })
    };
    let paramsInfoApp = await this.loginService.getInfoApi();
    let params = new HttpParams({fromString: 'idnotificacion='+idnotificacion.toString() + paramsInfoApp })

    return await this.http.post( environment.apiUrl + 'api/awt/setLeidoNotification', params, httpOptions)
          .pipe( map((info:any) => {
            let respuesta: Respuesta = { res: 0, msj: 'Respuesta no encontrada' }
            let data = info.data;
            if (data){
              data.forEach((elemento:any) => {
                respuesta = {
                  res: elemento.nres,
                  msj: elemento.msj
                };
                
              });
            }
            return respuesta;
          })).toPromise();
  }

  
}
