import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Loading } from '@app/models/Loading.model';
import { ToastMessageData, ToastMessageOptions } from '@common/models';
import { ToastService } from '@common/services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class UtilidadesService {

  constructor(
    private toastService: ToastService,
    private router: Router,
  ) { 

    this.loading = [];

  }

    setStorage(key:string, data:any){
      localStorage.setItem(key,data);
    }
    getStorage(key:string){
      return localStorage.getItem(key) ;
    }
    removeStorage(key:string){
      localStorage.removeItem(key);
    }
    
    setStorageSesion(key:string, data:any){
      sessionStorage.setItem(key,data);
    }
    getStorageSesion(key:string){
      return sessionStorage.getItem(key) ;
    }
    removeStorageSession(key:string){
      sessionStorage.removeItem(key);
    }


    showToast(header: ToastMessageData, body: ToastMessageData, options: ToastMessageOptions = {}) {
        const defaultOptions = {
            autohide: true,
            delay: 5000,
        };
        this.toastService.show(header, body, { ...defaultOptions, ...options });
    }

    showToastSinDelay(header: ToastMessageData, body: ToastMessageData, options: ToastMessageOptions = {}) {
      const defaultOptions = {
          autohide: true, // Cambia a true
          delay: 5000,
      };
      this.toastService.show(header, body, { ...defaultOptions, ...options });
  }

  cerrarSesionStorage(){
    localStorage.removeItem('usuario');
  }

  linkTo(ruta:string){
    this.router.navigate([ruta]);
  }

  getEntorno():string{
    return localStorage.getItem('entorno') || '';
  }

  /*
  getEntorno():string[]{
    let dataMenu = localStorage.getItem('menu-data') || '';
    if (dataMenu == ''){ dataMenu = '{ }'}
    let data = JSON.parse(dataMenu);

    let entorno:string[] = [];
    let isintra:boolean = false;
    let isextra:boolean = false;
    data.forEach((elemento : any) => {
      if (entorno.indexOf(elemento.entorno) < 0){
        entorno.push(elemento.entorno);
      }
      if (elemento.entorno == 'INTRANET'){
        isintra = true;
      }
      if (elemento.entorno == 'EXTRANET'){
        isextra = true;
      }
    });

    if (isintra && !isextra){
      localStorage.setItem('entorno','INTRANET');
    }else if (!isintra && isextra){
      localStorage.setItem('entorno','EXTRANET');
    }
    return entorno;
  }
  */
    


    loading: Loading[] = [];
    setLoading(dom:string, loading:boolean){
        let existe: boolean = false;
        this.loading.forEach((elemento)=>{
            if (elemento.dom == dom){
                existe = true;
                elemento.loading = loading;
            }
        });
        if (!existe){
            this.loading.push({dom: dom, loading: loading});
        }
    }
    getLoading(dom:string, all?:boolean){
        all = all == undefined ? false : all;
        let loading:boolean = false;
        if (all){
          this.loading.forEach((elemento)=>{
            if (elemento.dom.indexOf(dom) >= 0 && elemento.loading){
                loading = elemento.loading;
            }
          });
        }else{
          this.loading.forEach((elemento)=>{
              if (elemento.dom == dom){
                  loading = elemento.loading;
              }
          });
        }
        return loading;
    }
    getClassLoading(dom:string, vista?:number,classextra?:boolean) {
      if (vista == 1 || vista == undefined){
        return {
            'spinner-border': true,
            'spinner-border-sm': true,
            'spinner-oculto': !this.getLoading(dom),
            'classextra': classextra == undefined ? false : classextra
        };
      }

      if (vista == 2){
        return {
          'spinner-grow': true,
          'text-primary': true,
          'spinner-oculto': !this.getLoading(dom),
          'classextra': classextra == undefined ? false : classextra
        };
      }
    }
    getLoadings(){
      return this.loading;
    }

    delay(s: number) {
        return new Promise( resolve => setTimeout(resolve, s*1000) );
    }

}
