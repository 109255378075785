import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { LoginService } from '@app/services/login/login.service';
import { UtilidadesService } from '@app/services/utilidades.service';
import { NavigationService } from '@modules/navigation/services';

@Component({
    selector: 'sb-top-nav',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './top-nav.component.html',
    styleUrls: ['top-nav.component.scss'],
})
export class TopNavComponent implements OnInit {
    @Input() rtl = false;
    entorno:string = '';

    imagen:string = '';
    nomempresa:string = '';

    constructor(private navigationService: NavigationService,
        private utilidadesService: UtilidadesService,
        private loginService: LoginService,
    ) {}
    
    ngOnInit() {
        let usuario: any = this.loginService.getStorage();
        this.imagen = usuario.logoempresa;
        this.nomempresa = usuario.empresa;
        this.entorno = this.utilidadesService.getEntorno();
    }
    toggleSideNav() {
        this.navigationService.toggleSideNav();
    }
}
