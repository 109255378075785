import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoaderService } from '@app/services/loader.service';

@Component({
  selector: 'sbpro-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  constructor(public loader: LoaderService) { }

  ngOnInit(): void {
  }

}