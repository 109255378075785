import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from '@app/services/login/login.service';

import { SideNavItems, SideNavSection } from '@modules/navigation/models';
import { NavigationService } from '@modules/navigation/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sb-side-nav',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './side-nav.component.html',
    styleUrls: ['side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, OnDestroy {
    @Input() sidenavStyle!: string;
    @Input() sideNavItems!: SideNavItems;
    @Input() sideNavSections!: SideNavSection[];

    subscription: Subscription = new Subscription();
    routeDataSubscription!: Subscription;
    usuario: any;
    primernombre: string = '';

    constructor(public navigationService: NavigationService, 
        private loginService: LoginService,
        ) {}

    ngOnInit() {
        this.init();
    }

    init(){
        this.usuario = this.loginService.getStorage();
        
        let tam: number = this.usuario.nombre.indexOf(' ');
        if (tam > 0){
            this.primernombre = this.usuario.nombre.substring(0,tam);
        }else{
            this.primernombre = this.usuario.nombre;
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
