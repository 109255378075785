import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NotificacionService } from '@app/services/admin/notificacion.service';
import { Alert } from '@modules/alerts/models';
import { AlertsService } from '@modules/alerts/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sbpro-top-nav-alerts',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './top-nav-alerts.component.html',
    styleUrls: ['top-nav-alerts.component.scss'],
})
export class TopNavAlertsComponent implements OnInit {
    @Input() placement = 'bottom-right';
    @ViewChild('showAlert') showAlert!: TemplateRef<unknown>;
    dropdownClasses: string[] = [];
    alerts: Alert[] = [];
    constructor(
        private notificacionService: NotificacionService,
        private ngModal: NgbModal
    ) {}
    ngOnInit() {
        this.init();
    }

    async init(){
        this.alerts = await this.notificacionService.getNotifications();
    }

    alertdate: string = '';
    alerttxt: string = '';
    async verAlerta(alert:Alert){
        this.alerttxt = alert.text;
        this.alertdate = alert.date;
        if (alert.leido != 1){
            await this.notificacionService.setLeidoNotification((alert.idnotificacion || 0));
        }
        alert.leido = 1;
        this.ngModal.open(this.showAlert);
    }
}
