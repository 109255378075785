import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MensajeService } from '@app/services/admin/mensaje.service';
import { Message } from '@modules/messages/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sbpro-top-nav-messages',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './top-nav-messages.component.html',
    styleUrls: ['top-nav-messages.component.scss'],
})
export class TopNavMessagesComponent implements OnInit {
    @Input() placement = 'bottom-right';
    @ViewChild('showAlert') showAlert!: TemplateRef<unknown>;
    messages: Message[] = [];
    dropdownClasses: string[] = [];
    constructor(
        private mensajeService: MensajeService,
        private ngModal: NgbModal
        ) {}
    ngOnInit() {
        this.init();
    }

    async init(){
        this.messages = await this.mensajeService.getMensajes();
    }

    alerttxt: string = '';
    alertdetail: string = '';
    async verAlerta(alert:Message){
        this.alerttxt = alert.text;
        this.alertdetail = alert.details;
        this.ngModal.open(this.showAlert);
        if (alert.leido != 1){
            await this.mensajeService.setLeidoMensaje((alert.idmensaje || 0));
        }
        alert.leido = 1;
    }

}
