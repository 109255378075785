import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Combo } from '@app/models/Combo.model';
import { Loading } from '@app/models/Loading.model';
import { LoginService } from '@app/services/login/login.service';
import { UtilidadesService } from '@app/services/utilidades.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sb-login',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './login.component.html',
    styleUrls: ['login.component.scss'],
})
export class LoginComponent implements OnInit {

    

    @ViewChild('confirmationModal') confirmationModal!: TemplateRef<unknown>;
    @ViewChild('toastHeader') toastHeader!: TemplateRef<unknown>;
    @ViewChild('modalEmpresas') modalEmpresas!: TemplateRef<unknown>;

    loginForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        checkbox: [false],
    });
    empresas: Combo[] = [];
    empresasel: Combo | null = null;

    constructor(
        private fb: FormBuilder, 
        private router: Router, 
        private loginService: LoginService,
        private utilidadesService: UtilidadesService,
        private modalService: NgbModal,
    ) {}
    ngOnInit() {
        if (this.loginService.isAuthenticated()){
            let entorno = this.utilidadesService.getEntorno();
            if (entorno == 'EXTRANET'){
                this.router.navigate(['/extranet']);
            }else{
                this.router.navigate(['/intranet/inicio']);
            }
        }
    }

    
    async onSubmit() {
        if (this.loginForm.status === 'VALID') {
            let email = this.loginForm.get('email')?.value;
            let pass = this.loginForm.get('password')?.value || '';
            let idempresa = this.empresasel != null ? this.empresasel.id : 0;
            this.utilidadesService.setLoading('login',true);
            let rpta:any = await this.loginService.validarUsuario(email,pass, idempresa || 0);
            this.utilidadesService.setLoading('login',false);
            if (rpta.status == 'warning'){
                this.empresasel = null;
                this.modalService.dismissAll();
                this.empresas = [];
                rpta.data.forEach( (elemento:any) => {
                    this.empresas.push (
                        {
                            id: elemento.idempresa,
                            descripcion: elemento.nomempresa
                        }
                    )
                });
                this.modalService.open(this.modalEmpresas, {centered: true});
            }else if (rpta.status == 'success'){
                this.empresasel = null;
                this.modalService.dismissAll();
                let entorno = this.utilidadesService.getEntorno();
                if (entorno == 'EXTRANET'){
                    this.router.navigate(['/extranet']);
                }else{
                    this.router.navigate(['/intranet/inicio']);
                }
            }else{
                this.empresasel = null;
                this.modalService.dismissAll();
                this.utilidadesService.showToast(this.toastHeader,rpta.msj,{headerClasses:'text-danger'});
            }
        }

        for (const key in this.loginForm.controls) {
            const control = this.loginForm.controls[key];
            control.markAllAsTouched();
        }
    }

    async loginEmpresa(item:Combo){
        this.utilidadesService.setLoading('empresa'+item.id, true);
        //await this.utilidadesService.delay(10);
        this.empresasel = item;
        await this.onSubmit();
        this.utilidadesService.setLoading('empresa'+item.id, false);
    }

    /* Accessor Methods */

    get emailControl() {
        return this.loginForm.get('email') as FormControl;
    }

    get emailControlValid() {
        return this.emailControl.touched && !this.emailControlInvalid;
    }

    get emailControlInvalid() {
        return (
            this.emailControl.touched &&
            (this.emailControl.hasError('required') || this.emailControl.hasError('email'))
        );
    }

    get passwordControl() {
        return this.loginForm.get('password') as FormControl;
    }

    get passwordControlValid() {
        return this.passwordControl.touched && !this.passwordControlInvalid;
    }

    get passwordControlInvalid() {
        return (
            this.passwordControl.touched &&
            (this.passwordControl.hasError('required') ||
                this.passwordControl.hasError('minlength'))
        );
    }
}
