import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Alert } from '@modules/alerts/models';
import { Observable } from 'rxjs';
import { UtilidadesService } from '../utilidades.service';
import { LoginService } from '../login/login.service';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { Respuesta } from '@app/models/Respuesta.model';
import { Message } from '@modules/messages/models';

@Injectable({
  providedIn: 'root'
})
export class MensajeService {

  constructor(
    private http: HttpClient,
    private utilidadesService: UtilidadesService,
    private loginService: LoginService,
  ) { }

  async getMensajes(): Promise<Message[]> {

    var usuario = await this.loginService.getStorage();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + usuario.token
      })
    };
    let paramsInfoApp = await this.loginService.getInfoApi();
    let params = new HttpParams({fromString: paramsInfoApp })
    
    return await this.http.post( environment.apiUrl + 'api/awt/getMensajes', params, httpOptions)
          .pipe( map((info:any) => {
            let mensajes:Message[] = [];
            let data = info.data;
            if (data){
              data.forEach((elemento : any) => {
                let alert = {
                  idmensaje: elemento.idmensaje,
                  image: elemento.image,
                  text: elemento.text,
                  details: elemento.details,
                  leido: elemento.flg_leido
                };
                mensajes.push(alert);
              });
            }
            return mensajes;
          })).toPromise();
  }

  getMensajesObservale(page:number, pageSize:number, criterio:string): Observable<Message[]> {

    var usuario = this.loginService.getStorage();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + usuario.token
      })
    };
    let paramsInfoApp = this.loginService.getInfoApi();
    let params = new HttpParams({fromString: 'page=' + page.toString() + '&pageSize=' + pageSize.toString() + '&criterio=' + criterio + paramsInfoApp })
    
    return this.http.post( environment.apiUrl + 'api/awt/getMensajesPaginator', params, httpOptions)
          .pipe( map((info:any) => {
            let mensajes:Message[] = [];
            let total = info.data.total;
            let data = info.data.items;
            if (data){
              data.forEach((elemento : any) => {
                let alert:Message = {
                  idmensaje: elemento.idmensaje,
                  image: '',
                  details: '',
                  text: elemento.text,
                  date: elemento.date,
                  leido: elemento.flg_leido,
                  username: elemento.username,
                  totalPaginator: total
                };
                mensajes.push(alert);
              });
            }

            // 1. # Valida error
            if (info.status == 'error'){
              this.utilidadesService.showToast('Error',info.msj,{headerClasses:'text-danger'});
            }

            return mensajes;
          }));
  }

  async setLeidoMensaje(idmensaje:number):Promise<Respuesta>{
    var usuario = await this.loginService.getStorage();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + usuario.token
      })
    };
    let paramsInfoApp = await this.loginService.getInfoApi();
    let params = new HttpParams({fromString: 'idmensaje='+idmensaje.toString() + paramsInfoApp })

    return await this.http.post( environment.apiUrl + 'api/awt/setLeidoMensaje', params, httpOptions)
          .pipe( map((info:any) => {
            let respuesta: Respuesta = { res: 0, msj: 'Respuesta no encontrada' }
            let data = info.data;
            if (data){
              data.forEach((elemento:any) => {
                respuesta = {
                  res: elemento.nres,
                  msj: elemento.msj
                };
                
              });
            }
            return respuesta;
          })).toPromise();
  }

  
}
