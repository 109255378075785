import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ChildActivationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    template: `
        <sbpro-toast-view class="ngb-toasts" aria-live="polite" aria-atomic="true">
        </sbpro-toast-view
        ><router-outlet></router-outlet>
        <sbpro-spinner></sbpro-spinner>
    `,
})
export class AppComponent {
    constructor(public router: Router, private titleService: Title) {
        this.router.events
            .pipe(filter(event => event instanceof ChildActivationEnd))
            .subscribe(event => {
                let snapshot = (event as ChildActivationEnd).snapshot;

                while (snapshot.firstChild !== null) {
                    snapshot = snapshot.firstChild;
                }
                this.titleService.setTitle(snapshot.data.title || 'Portal de proveedores');
            });
    }
    
    /*
    validateTokenTime(){
        setInterval( () => {
            let usuario: any = localStorage.getItem('usuario');
            if (usuario && usuario != ''){
                let usuarioJson = JSON.parse(usuario);
                if (usuarioJson.token && usuarioJson.expires_in && usuarioJson.expires_in > 0){
                    let fecha1 = new Date(usuario.fechainiciotoken);
                    let fecha2 = new Date();
                    let resta = fecha2.getTime() - fecha1.getTime();
                    let segundos = Math.round(resta/ (1000));
                }
            }
        });
    }
    */
}
