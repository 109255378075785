import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OpcionService } from '@app/services/admin/opcion.service';
import { LoginService } from '@app/services/login/login.service';
import { UtilidadesService } from '@app/services/utilidades.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'sbpro-top-nav-user',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './top-nav-user.component.html',
    styleUrls: ['top-nav-user.component.scss'],
})
export class TopNavUserComponent implements OnInit {

    @ViewChild('changePasswordModal') confirmationModal!: TemplateRef<unknown>;
    @ViewChild('toastHeader') toastHeader!: TemplateRef<unknown>;
    toasttitle: string = '';
    entorno:string = 'EXTRANET';
    newentorno:string = 'INTRANET';
    
    changeForm = this.fb.group({
          password: ['', [Validators.required, Validators.minLength(6)]],
          password2: ['', [Validators.required, Validators.minLength(6)]],
      });

    @Input() placement = 'bottom-right';
    dropdownClasses: string[] = [];

    // Variables
    usuario: any;
    openForm: any;

    constructor(
        private loginService: LoginService,
        private modalService: NgbModal,
        private router: Router,
        private fb: FormBuilder, 
        private utilidadesService: UtilidadesService
    ) {}
    ngOnInit() {
        this.init();
    }

    init(){
        this.usuario = this.loginService.getStorage();

        this.entorno = this.utilidadesService.getStorage('entorno') || 'EXTRANET';
        this.newentorno = this.entorno == 'EXTRANET' ? 'INTRANET' : 'EXTRANET';
    }

    async logout(){
        await this.loginService.cerrarSesion();
        this.router.navigate(['login']);
    }

    changeClave(){
        this.openForm = this.modalService.open(this.confirmationModal).result.then(
            result => {
                if (result === 'DASHBOARD') {
                }
            },
            reason => {}
        );
    }

    irAEntorno(){
        this.newentorno = this.entorno == 'EXTRANET' ? 'INTRANET' : 'EXTRANET';
        this.utilidadesService.setStorage('entorno',this.newentorno);
        if (this.newentorno == 'INTRANET'){
            this.router.navigate(["intranet/inicio"]);
        }else{
            this.router.navigate(["extranet"]);
        }
        setTimeout(() => {window.location.reload();},100);
        
    }


    async onSubmit() {
        if (this.changeForm.status === 'VALID') {
            let pass = this.changeForm.get('password')?.value || '';
            let pass2 = this.changeForm.get('password2')?.value || '';
            if (pass == pass2){
                this.utilidadesService.setLoading('cambiar',true);
                let rpta:any = await this.loginService.cambiarClaveIn(pass,pass);
                this.utilidadesService.setLoading('cambiar',false);
                if (rpta.status == 'success'){
                    this.modalService.dismissAll();
                    this.utilidadesService.showToast(this.toastHeader,rpta.msj,{headerClasses:'text-success'});
                }else{
                    this.utilidadesService.showToastSinDelay(this.toastHeader,rpta.msj,{headerClasses:'text-danger'});
                }
            }else{
                this.utilidadesService.showToastSinDelay(this.toastHeader,'Las contraseñas no son iguales.',{headerClasses:'text-danger'});
            }
        }

        for (const key in this.changeForm.controls) {
            const control = this.changeForm.controls[key];
            control.markAllAsTouched();
        }
    }

    /* Accessor Methods */

    get passwordControl() {
        return this.changeForm.get('password') as FormControl;
    }

    get passwordControlValid() {
        return this.passwordControl.touched && !this.passwordControlInvalid;
    }

    get passwordControlInvalid() {
        return (
            this.passwordControl.touched &&
            (this.passwordControl.hasError('required') ||
                this.passwordControl.hasError('minlength'))
        );
    }

    get password2Control() {
        return this.changeForm.get('password2') as FormControl;
    }

    get password2ControlValid() {
        return this.password2Control.touched && !this.password2ControlInvalid;
    }

    get password2ControlInvalid() {
        return (
            this.password2Control.touched &&
            (this.password2Control.hasError('required') ||
                this.password2Control.hasError('minlength'))
        );
    }
}
