import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '@app/services/login/login.service';
import { UtilidadesService } from '@app/services/utilidades.service';

@Component({
  selector: 'sbpro-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  @ViewChild('toastHeader') toastHeader!: TemplateRef<unknown>;
  _token: string = '';

  changeForm = this.fb.group({
        password: ['', [Validators.required, Validators.minLength(6)]],
        password2: ['', [Validators.required, Validators.minLength(6)]],
    });

    constructor(
        private fb: FormBuilder, 
        private router: Router, 
        private loginService: LoginService,
        private utilidadesService: UtilidadesService,
        private activeRoute: ActivatedRoute
    ) {

    }
    ngOnInit() {

      this.activeRoute.queryParams
        .subscribe(params => {

          this._token = params._token;
        }
      );

    }

    async onSubmit() {
        if (this.changeForm.status === 'VALID') {
            let pass = this.changeForm.get('password')?.value || '';
            let pass2 = this.changeForm.get('password2')?.value || '';
            if (pass == pass2){
              this.utilidadesService.setLoading('cambiar',true);
              let rpta:any = await this.loginService.cambiarClave(pass,pass, this._token);
              this.utilidadesService.setLoading('cambiar',false);
              if (rpta.status == 'success'){
                  setTimeout(() => {
                    this.router.navigate(["/extranet"]);
                  }, 5000);
                  this.utilidadesService.showToastSinDelay(this.toastHeader,rpta.msj,{headerClasses:'text-success'});
              }else{
                  this.utilidadesService.showToastSinDelay(this.toastHeader,rpta.msj,{headerClasses:'text-danger'});
              }
            }else{
              this.utilidadesService.showToast(this.toastHeader,'Las contraseñas no son iguales.',{headerClasses:'text-danger'});
            }
        }

        for (const key in this.changeForm.controls) {
            const control = this.changeForm.controls[key];
            control.markAllAsTouched();
        }
    }

    /* Accessor Methods */

    get passwordControl() {
        return this.changeForm.get('password') as FormControl;
    }

    get passwordControlValid() {
        return this.passwordControl.touched && !this.passwordControlInvalid;
    }

    get passwordControlInvalid() {
        return (
            this.passwordControl.touched &&
            (this.passwordControl.hasError('required') ||
                this.passwordControl.hasError('minlength'))
        );
    }

    get password2Control() {
        return this.changeForm.get('password2') as FormControl;
    }

    get password2ControlValid() {
        return this.password2Control.touched && !this.password2ControlInvalid;
    }

    get password2ControlInvalid() {
        return (
            this.password2Control.touched &&
            (this.password2Control.hasError('required') ||
                this.password2Control.hasError('minlength'))
        );
    }


}
