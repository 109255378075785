import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppCommonModule } from '@common/app-common.module';
import { NavigationModule } from '@modules/navigation/navigation.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { LoadingInterceptor } from './interceptor/loading.interceptor';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { HttpErrorInterceptorInterceptor } from './interceptor/http-error-interceptor.interceptor';
import { OnlynumberDirective } from './directives/onlynumber.directive';
import { OnlynumberPuntoDirective } from './directives/onlynumberpunto.directive';
import { CotizacionesComponent } from './pages/intranet/cotizaciones/cotizaciones.component';

@NgModule({
    declarations: [
        AppComponent, 
        SpinnerComponent,
        LoginComponent, 
        ForgotPasswordComponent, 
        ChangePasswordComponent, CotizacionesComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        AppCommonModule.forRoot(),
        NavigationModule.forRoot(),
        
        ReactiveFormsModule,
        FormsModule,
    ],
    providers: [
        {
        provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
        },
        {
        provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptorInterceptor, multi: true
        }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
