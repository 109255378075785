import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilidadesService } from '../utilidades.service';
import { LoginService } from '../login/login.service';
import { SideNavItem, SideNavItems, SideNavSection } from '@modules/navigation/models';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { Menu } from '@app/models/menu.model';
import { ChartLine } from '@app/models/ChartLine.model';
import { Respuesta } from '@app/models/Respuesta.model';
import { Comprobante } from '@app/models/Comprobante.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpcionService {

  constructor(
    private http: HttpClient,
    private utilidadesService: UtilidadesService,
    private loginService: LoginService
  ) { }


  async getOpciones(tipo:string): Promise<Menu> {

    var usuario = await this.loginService.getStorage();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + usuario.token
      })
    };
    let paramsInfoApp = await this.loginService.getInfoApi();
    let params = new HttpParams({fromString: paramsInfoApp })
    
    return await this.http.post( environment.apiUrl + 'api/awt/getOpciones', params, httpOptions)
          .pipe( map((info:any) => {
            this.utilidadesService.setStorage('menu-data', JSON.stringify(info.data));
            return this.getOpcionesTipo(tipo);
          })).toPromise();
  }

  getOpcionesTipo(tipo:string): Menu{
    let dataMenu = this.utilidadesService.getStorage('menu-data') || '';
    if (dataMenu == ''){ dataMenu = '{ }'}
    let data = JSON.parse(dataMenu);

    let sideNavSections:SideNavSection[] = [];
    let sideNavItems:SideNavItems = {};
    if (data){
      let titulo:string = '';
      let opciones:string[] = [];
      let datafiltrada:any = [];
      data.forEach((elemento : any) => {
        if (elemento.entorno == tipo){
          datafiltrada.push(elemento);
        }
      });

      datafiltrada.forEach((elemento : any) => {
        if (elemento.nombreModulo != titulo && titulo != ''){
          
          let sideNavSection:SideNavSection = {
            text: titulo,
            items: opciones
          }
          sideNavSections.push(sideNavSection);
          opciones = [];
        }
        let sideNavItem:SideNavItem = {
          icon: elemento.icono.trim() == '' ? 'book-open' : elemento.icono,
          link: elemento.ruta,
          text: elemento.nombreOpcion
        }
        sideNavItems[elemento.txtid] = sideNavItem;

        titulo = elemento.nombreModulo;
        opciones.push(elemento.txtid);

      });
      if (titulo != ''){
        let sideNavSection:SideNavSection = {
          text: titulo,
          items: opciones
        }
        sideNavSections.push(sideNavSection);
      }
    }
    let menu: Menu = {
      sections: sideNavSections,
      items: sideNavItems
    }
    return menu;
  }

  async getResumenComprobantes(idmoneda:string): Promise<ChartLine[]> {

    var usuario = await this.loginService.getStorage();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + usuario.token
      })
    };
    let paramsInfoApp = await this.loginService.getInfoApi();
    let params = new HttpParams({fromString: 
      'idmoneda=' + idmoneda + 
      paramsInfoApp })
    
    return await this.http.post( environment.apiUrl + 'api/awt/getResumenComprobantes', params, httpOptions)
          .pipe( map((info:any) => {
            let line:ChartLine[] = [];
            let data = info.data;
            if (data){
              data.forEach((elemento : any) => {
                line.push({
                  label: elemento.periodo,
                  valor: elemento.total
                })
              });
            }

            // 1. # Valida error
            if (info.status == 'error'){
              this.utilidadesService.showToast('Error',info.msj,{headerClasses:'text-danger'});
            }
            
            return line;
          })).toPromise();
  }

  async getResumenComprobantesxEstado(): Promise<ChartLine[]> {

    var usuario = await this.loginService.getStorage();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + usuario.token
      })
    };
    let paramsInfoApp = await this.loginService.getInfoApi();
    let params = new HttpParams({fromString: 
      paramsInfoApp })
    
    return await this.http.post( environment.apiUrl + 'api/awt/getResumenComprobantesxEstado', params, httpOptions)
          .pipe( map((info:any) => {
            let line:ChartLine[] = [];
            let data = info.data;
            if (data){
              data.forEach((elemento : any) => {
                line.push({
                  label: elemento.estado,
                  valor: elemento.total,
                  color: elemento.color
                })
              });
            }

            // 1. # Valida error
            if (info.status == 'error'){
              this.utilidadesService.showToast('Error',info.msj,{headerClasses:'text-danger'});
            }
            
            return line;
          })).toPromise();
  }

  getResumenComprobantesTopVencer(): Observable<Comprobante[]> {

    var usuario = this.loginService.getStorage();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + usuario.token
      })
    };
    let paramsInfoApp = this.loginService.getInfoApi();
    let params = new HttpParams({fromString: 
      paramsInfoApp })
    
    return this.http.post( environment.apiUrl + 'api/awt/getResumenComprobantesTopVencer', params, httpOptions)
          .pipe( map((info:any) => {
            let line:Comprobante[] = [];
            let data = info.data;
            if (data){
              data.forEach((elemento : any) => {
                line.push({
                  tipo: elemento.tipo,
                  serie: elemento.serie,
                  numero: elemento.numero,
                  fechavencimiento: elemento.fechavencimiento,
                  total: elemento.total,
                  simbolo: elemento.simbolo
                })
              });
            }

            // 1. # Valida error
            if (info.status == 'error'){
              this.utilidadesService.showToast('Error',info.msj,{headerClasses:'text-danger'});
            }
            
            return line;
          }));
  }

  

  

  async getComunicado(): Promise<string> {

    var usuario = await this.loginService.getStorage();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + usuario.token
      })
    };
    let paramsInfoApp = await this.loginService.getInfoApi();
    let params = new HttpParams({fromString: 
      paramsInfoApp })
    
    return await this.http.post( environment.apiUrl + 'api/awt/getComunicado', params, httpOptions)
          .pipe( map((info:any) => {
            let data = info.data;
            let comunicado:string = '';
            if (data){
              data.forEach((elemento : any) => {
                comunicado = elemento.flg_comunicado_txt
              });
            }

            // 1. # Valida error
            if (info.status == 'error'){
              this.utilidadesService.showToast('Error',info.msj,{headerClasses:'text-danger'});
            }
            
            return comunicado;
          })).toPromise();
  }

  async setLeidoComunicado():Promise<Respuesta>{
    var usuario = await this.loginService.getStorage();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + usuario.token
      })
    };
    let paramsInfoApp = await this.loginService.getInfoApi();
    let params = new HttpParams({fromString: paramsInfoApp })

    return await this.http.post( environment.apiUrl + 'api/awt/setLeidoComunicado', params, httpOptions)
          .pipe( map((info:any) => {
            let respuesta: Respuesta = { res: 0, msj: 'Respuesta no encontrada' }
            let data = info.data;
            if (data){
              data.forEach((elemento:any) => {
                respuesta = {
                  res: elemento.nres,
                  msj: elemento.msj
                };
                
              });
            }
            return respuesta;
          })).toPromise();
  }

}
