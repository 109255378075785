import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { SBRouteData } from '@modules/navigation/models';
import { GuardGuard } from './guards/guard.guard';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';

let entorno = localStorage.getItem('entorno') || '';
let redirectTo = entorno == 'EXTRANET' ? '/extranet' : '/intranet';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: redirectTo,
    },
    {
        path: 'login',
        component: LoginComponent,
        data: {
            title: 'Login - Portal de proveedores',
        } as SBRouteData,
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: {
            title: 'Olvide mi contraseña - Portal de proveedores',
        } as SBRouteData,
    },
    {
        path: 'change-password',
        component: ChangePasswordComponent,
        data: {
            title: 'Cambiar mi contraseña - Portal de proveedores',
        } as SBRouteData,
    },
    // Extranet
    {
        path: 'extranet',
        canActivate: [GuardGuard],
        loadChildren: () =>
            import('modules/dashboard/dashboard-routing.module').then(
                m => m.DashboardRoutingModule
            ),
    },
    // Intranet
    {
        path: 'intranet',
        canActivate: [GuardGuard],
        loadChildren: () =>
            import('modules/dashboard/dashboard-routing.module').then(
                m => m.DashboardRoutingModule
            ),
    },
    {
        path: '**',
        pathMatch: 'full',
        loadChildren: () =>
            import('modules/error/error-routing.module').then(m => m.ErrorRoutingModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
