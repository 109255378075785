// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    //apiUrl: 'http://ppperu.com/',
    apiUrl: 'https://www.app.bitforce.pe/',
    headers : {
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8", 
        'Accept': 'application/json',
        "cache-control": "no-cache", 
        "Access-Control-Allow-Origin": "*", 
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers",
        "Access-Control-Allow-Credentials" : "true",
        "Access-Control-Allow-Methods" : "GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT",  
    },
    firebase: {
        apiKey: "AIzaSyB8iXddaOcOw-ECXMAjnhM519bkQa57YbY",
        authDomain: "bittechnology-7acc2.firebaseapp.com",
        projectId: "bittechnology-7acc2",
        storageBucket: "bittechnology-7acc2.appspot.com",
        messagingSenderId: "694425151087",
        appId: "1:694425151087:web:fadec610e5535b62640f9a",
        measurementId: "G-J3QNDCZQ66"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
