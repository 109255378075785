import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UtilidadesService } from '@app/services/utilidades.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class HttpErrorInterceptorInterceptor implements HttpInterceptor {

  constructor(
    private utilidadesService: UtilidadesService,
    private modalService: NgbModal,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error:any) => {
        let errorMessage = 'Por favor inicie sesión nuevamente';
        if (error instanceof ErrorEvent) {
          // client-side error
          //errorMessage = `Client-side error: ${error.error.message}`;
        } else {
          // backend error
          //errorMessage = `Server-side error: ${error.status} ${error.message}`;
          
        }
        if (request.url.indexOf("loginAppW")>0){
          errorMessage = 'Las credenciales ingresadas son incorrectas';
        }
        this.utilidadesService.showToast('Aviso',errorMessage,{headerClasses:'text-danger'});
        if (error.status == 401){
          this.modalService.dismissAll();
          this.utilidadesService.cerrarSesionStorage();
          this.utilidadesService.linkTo('login');
        }
        return throwError(errorMessage);
      })
    );
  }
}
