import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'

import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UtilidadesService } from '../utilidades.service';
import { Combo } from '@app/models/Combo.model';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
      private http: HttpClient,
      private router: Router,
      private utilidadesService: UtilidadesService,
      ) {
    }

    getInfoApi():string{
      
      return '&route=' + this.router.url;

    }

    getRoute():string{
      return this.router.url;
    }

   canActivate(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot)  {
       return true;
   }

   isAuthenticated(){
    let usuario = this.getStorage();

      if (usuario && usuario.token && usuario.expires_in > 0){
        let fecha1 = new Date(usuario.fechainiciotoken);
        let fecha2 = new Date();
        let resta = fecha2.getTime() - fecha1.getTime();
        let segundos = Math.round(resta/ (1000));
        if (usuario.expires_in > (segundos - 30)){
          return true;
        }else{
          return false;
        }
      }else{
        return false;
      }
   }

   async validarUsuario(usuario:string, clave:string, idempresa: number){
    const httpOptions = {
      headers: new HttpHeaders(environment.headers)
    };
    
    let params = new HttpParams({fromString:
      'email='+usuario+
      '&password='+clave+ 
      '&idempresa='+idempresa+
      this.getInfoApi()})
    return await this.http.post( environment.apiUrl + 'api/awt/loginAppW'
      ,params).
      
      pipe(
        
        map( (data : any) => {
        if (data.status == "success"){
            let info = data.data;
            /* SETEAR ENTORNO */
            if (info.isintra == 1 && info.isextra == 0){
              localStorage.setItem('entorno','INTRANET');
            }else if (info.isintra == 0 && info.isextra == 1){
              localStorage.setItem('entorno','EXTRANET');
            }else {
              localStorage.setItem('entorno','EXTRANET');
            }
            info.fechainiciotoken = new Date();

            this.utilidadesService.setStorage('usuario', JSON.stringify(info));
        }
        if (data.status == 'error'){
          this.utilidadesService.showToast('Error',data.msj,{headerClasses:'text-danger'});
        }
        return data;
      })
      
      ).toPromise();
   }

   async cambiarClave(clave:string, clave2:string, token:string){
    const httpOptions = {
      headers: new HttpHeaders(environment.headers)
    };
    let params = new HttpParams({fromString:'clave='+clave+'&clave2='+clave2+'&_token=' + token+ this.getInfoApi()})
    return await this.http.post( environment.apiUrl + 'api/awt/changePassword'
      ,params).
      pipe(
        map( (data : any) => {

          // # 1. Si hay error entonces mostrar mensaje
          if (data.status == 'error'){
            this.utilidadesService.showToast('Error',data.msj,{headerClasses:'text-danger'});
          }

        return data;
      })
      ).toPromise();
   }
   
   async cambiarClaveIn(clave:string, clave2:string){
    let usuario = this.getStorage();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + usuario.token
      })
    };
    let params = new HttpParams({fromString:'clave='+clave+'&clave2='+clave2+ this.getInfoApi()})
    return await this.http.post( environment.apiUrl + 'api/awt/changePasswordIn'
      ,params,httpOptions).
      pipe(
        map( (data : any) => {

          // # 1. Si hay error entonces mostrar mensaje
          if (data.status == 'error'){
            this.utilidadesService.showToast('Error',data.msj,{headerClasses:'text-danger'});
          }

        return data;
      })
      ).toPromise();
   }

   async setOlvideClave(email:string){
    let params = new HttpParams({fromString:'email='+email + this.getInfoApi()})
    return await this.http.post( environment.apiUrl + 'api/awt/setOlvideClave'
      ,params).pipe(map( (data : any) => {

        // # 1. Si hay error entonces mostrar mensaje
        if (data.status == 'error'){
          this.utilidadesService.showToast('Error',data.msj,{headerClasses:'text-danger'});
        }

        return data;
      })).toPromise();
   }

   async setLogout(){
    let usuario = this.getStorage();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + usuario.token
      })
    };

    let params = new HttpParams({fromString:'some=some' + this.getInfoApi()})
    return await this.http.post( environment.apiUrl + 'api/awt/logout'
      ,params, httpOptions).pipe(map( (data : any) => {

        // # 1. Si hay error entonces mostrar mensaje
        if (data.status == 'error'){
          this.utilidadesService.showToast('Error',data.msj,{headerClasses:'text-danger'});
        }

        return data;
      })).toPromise();
   }

   getStorage(){
    let usuario:string = this.utilidadesService.getStorage('usuario') || '';
    if (usuario == ''){ usuario = '{ }'}
    return JSON.parse(usuario);
   }

   async cerrarSesion(){
      await this.setLogout();
      this.utilidadesService.removeStorage('usuario');
   }

}
